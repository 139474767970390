import API_LINKS from "@/view/pages/painel/automacao/api.links";

export default class Utils {
  static setInterval(funcao, timeout) {
    return setInterval(funcao, timeout);
  }

  /**
   * Retorna o link do webhook da campanha.
   * @param campanha - Campanha a ser usada para pegar o token do webhook.
   * @returns {string} - Link do webhook da campanha.
   */
  static getLinkWebhookCampanha(campanha) {
    if (!campanha.webhook || !campanha.webhook.mdw_token) {
      return "";
    }
    return (
      process.env.VUE_APP_COMUNICANTE_API +
      API_LINKS.campanhaWebhook +
      "?token=" +
      (campanha ? campanha.webhook.mdw_token : "")
    );
  }
}
